import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import moment from 'moment'
// Global Components
import './global-components'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
Vue.use(ToastificationContent)

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  methods: {
    showToast(variant, type, text) {
        let icon, title;
        switch (variant) {
            case 'success':
                icon = 'CheckIcon'
                break;
            default:
                break;
        }

        switch (type) {
            case 'save':
                title = this.$t('Saved Successfully')
                break;
            case 'sent':
                title = this.$t('Sent Successfully')
                break;
            default:
                break;
        }

        this.$toast({
            component: ToastificationContent,
            props: {
                title,
                icon,
                text,
                variant,
            },
        })
    },
    showAlert(icon, text) {
        let title;

        switch (icon) {
            case 'error':
                title = this.$t('Error!')
                break;
            default:
                break;
        }

        // text
        // TODO: handel text messages if it's array

        this.$swal({
            title,
            text,
            icon,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
        })
    }
  },
}).$mount('#app')

Vue.filter('formatDate', function(value) {
  if (value) {
      return moment(String(value)).format('YYYY-MM-DD hh:mm a')
  }
 })
 Vue.filter('formatDateWithoutHours', function(value) {
   if (value) {
       return moment(String(value)).format('YYYY-MM-DD')
   }
  })
