import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import axios from 'axios'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    status:'',
    token: localStorage.getItem('accessToken') || null,
    isLoggedIn: localStorage.getItem('accessToken') ? true : false,
    user: JSON.parse(localStorage.getItem('userData')) || null,
    store: JSON.parse(localStorage.getItem('storeData')) || null,
    error: '',
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    auth_request(state) {
        state.status = 'loading'
    },
    auth_success(state,token) {
        state.status = 'success'
        state.token = token
    },
    set_user(state,user) {
        state.user = user
    },
    handle_error(state,error){
        state.error = error
    },
  },
  actions: {
    //   register({commit},user){
    //       return new Promise((resolve,reject) => {
    //           useJwt.register({name:user.name, email:user.email,password:user.password})
    //           .then(response => {
    //               const token = 'Bearer '+response.data.data.token
    //               const user = response.data.data.user
    //               useJwt.setToken(response.data.data.token)
    //               localStorage.setItem('userData', JSON.stringify(response.data.data.user))
    //               localStorage.setItem('token',token)
    //               commit('auth_success', token, user)
    //               resolve(response)
    //           })
    //           .catch(err => {
    //               localStorage.removeItem('token')
    //               reject(err)
    //           })
    //       })
    //   },
 },
  getters: {
      authStatus: state => state.status,
      getUser: state => state.user,
      getError: state => state.error
  }
})
