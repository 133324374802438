import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    // categories routes
    {
      path: '/categories',
      name: 'categories',
      component: () => import('@/views/categories/index.vue'),
      meta: {
        pageTitle: 'Categories',
        breadcrumb: [
          {
            text: 'Categories',
            active: true,
          },
        ],
        permission_name: 'index categories'
      },
    },
    {
      path: '/category/create',
      name: 'category-create',
      component: () => import('@/views/categories/create.vue'),
      meta: {
        pageTitle: 'Create Category',
        navActiveLink: 'category',
        breadcrumb: [
          {
            text: 'Categories',
            to: { name: 'categories' }
          },
          {
            text: 'Create Category',
            active: true,
          },
        ],
        permission_name: 'create categories'
      },
    },
    {
      path: '/categories/:id',
      name: 'categories-edit',
      component: () => import('@/views/categories/edit.vue'),
      meta: {
        pageTitle: 'Edit Category',
        navActiveLink: 'categories',
        breadcrumb: [
          {
            text: 'Categories',
            to: { name: 'categories' }
          },
          {
            text: 'Edit Category',
            active: true,
          },
        ],
        permission_name: 'edit categories'
      },
    },
    // shoppers routes
    {
      path: '/shoppers',
      name: 'shoppers',
      component: () => import('@/views/shoppers/index.vue'),
      meta: {
        pageTitle: 'Shoppers',
        breadcrumb: [
          {
            text: 'Shoppers',
            active: true,
          },
        ],
        permission_name: 'index shoppers'
      },
    },
    {
      path: '/shoppers/:id',
      name: 'shopper',
      component: () => import('@/views/shoppers/show.vue'),
      meta: {
        pageTitle: 'Shopper Show',
        breadcrumb: [
          {
            text: 'Shoppers',
            to: { name: 'shoppers' }
          },
          {
            text: 'Shopper Show',
            active: true,
          },
        ],
        permission_name: 'show shoppers'
      },
    },
    {
      path: '/coupons',
      name: 'coupons',
      component: () => import('@/views/coupons/index.vue'),
      meta: {
        pageTitle: 'Coupons',
        breadcrumb: [
          {
            text: 'Coupons',
            active: true,
          },
        ],
        permission_name: 'index coupon'
      },
    },
    {
      path: '/coupons/create',
      name: 'coupons-create',
      component: () => import('@/views/coupons/create.vue'),
      meta: {
        pageTitle: 'Create Coupon',
        breadcrumb: [
          {
            text: 'Coupons',
            to: { name: 'coupons' }
          },
          {
            text: 'Create Coupon',
            active: true,
          },
        ],
        permission_name: 'create coupon'
      },
    },
    {
      path: '/coupons/:id',
      name: 'coupons-edit',
      component: () => import('@/views/coupons/edit.vue'),
      meta: {
        pageTitle: 'Edit Coupon',
        navActiveLink: 'coupons',
        breadcrumb: [
          {
            text: 'Coupons',
            to: { name: 'coupons' }
          },
          {
            text: 'Edit Coupon',
            active: true,
          },
        ],
        permission_name: 'edit coupon'
      },
    },
    {
      path: '/orders',
      name: 'orders',
      component: () => import('@/views/orders/index.vue'),
      meta: {
        pageTitle: 'Orders',
        breadcrumb: [
          {
            text: 'Orders',
            active: true,
          },
        ],
        permission_name: 'index orders'
      },
    },
    {
      path: '/orders/:id',
      name: 'order',
      component: () => import('@/views/orders/show.vue'),
      meta: {
        pageTitle: 'Order Show',
        breadcrumb: [
          {
            text: 'Orders',
            to: { name: 'orders' }
          },
          {
            text: 'Order Show',
            active: true,
          },
        ],
        permission_name: 'show orders'
      },
    },

    // Order Ratings
    {
      path: '/order-ratings',
      name: 'orders-ratings',
      component: () => import('@/views/order-ratings/index.vue'),
      meta: {
        pageTitle: 'Orders Ratings',
        breadcrumb: [
          {
            text: 'Orders Ratings',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/order-ratings/:id',
    //   name: 'order-rating',
    //   component: () => import('@/views/order-ratings/show.vue'),
    //   meta: {
    //     pageTitle: 'Order Rating Show',
    //     breadcrumb: [
    //       {
    //         text: 'Orders Ratings',
    //         to: { name: 'orders-ratings' }
    //       },
    //       {
    //         text: 'Order Rating Show',
    //         active: true,
    //       },
    //     ],
    //   },
    // },

    // products routes
    {
      path: '/products',
      name: 'products',
      component: () => import('@/views/products/index.vue'),
      meta: {
        pageTitle: 'Products',
        breadcrumb: [
          {
            text: 'Products',
            active: true,
          },
        ],
        permission_name: 'index products'
      },
    },
    {
      path: '/products/create',
      name: 'products-create',
      component: () => import('@/views/products/create.vue'),
      meta: {
        pageTitle: 'Create Product',
        breadcrumb: [
          {
            text: 'Products',
            to: { name: 'products' }
          },
          {
            text: 'Create Product',
            active: true,
          },
        ],
        permission_name: 'create products'
      },
    },
    {
      path: '/products/:id/edit/:filter',
      name: 'products-edit',
      component: () => import('@/views/products/edit.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application',
        navActiveLink: 'products',
        permission_name: 'edit products'
      },
    },

    // reports routes
    {
      path: '/reports',
      name: 'reports',
      component: () => import('@/views/reports/Reports.vue'),
      meta: {
        pageTitle: 'Reports',
        breadcrumb: [
          {
            text: 'Reports',
            active: true,
          },
        ],
        permission_name: 'show reports'
      },
    },

    // pages routes
    {
      path: '/pages',
      name: 'pages',
      component: () => import('@/views/pages/index.vue'),
      meta: {
        pageTitle: 'Pages',
        breadcrumb: [
          {
            text: 'Pages',
            active: true,
          },
        ],
        permission_name: 'index pages'
      },
    },
    {
      path: '/pages/:id',
      name: 'pages-edit',
      component: () => import('@/views/pages/edit.vue'),
      meta: {
        pageTitle: 'Edit Page',
        navActiveLink: 'pages',
        breadcrumb: [
          {
            text: 'Pages',
            to: { name: 'pages' }
          },
          {
            text: 'Edit Page',
            active: true,
          },
        ],
        permission_name: 'edit pages'
      },
    },
    {
      path: '/page/create',
      name: 'pages-create',
      component: () => import('@/views/pages/create.vue'),
      meta: {
        pageTitle: 'Create Page',
        navActiveLink: 'pages',
        breadcrumb: [
          {
            text: 'Pages',
            to: { name: 'pages' }
          },
          {
            text: 'Create Page',
            active: true,
          },
        ],
        permission_name: 'create pages'
      },
    },
    // daily deals routes
    {
      path: '/daily-deals',
      name: 'daily-deals',
      component: () => import('@/views/daily-deals/index.vue'),
      meta: {
        pageTitle: 'Daily Deals',
        breadcrumb: [
          {
            text: 'Daily Deals',
            active: true,
          },
        ],
      },
    },
    {
      path: '/daily-deals/:id',
      name: 'daily-deals-edit',
      component: () => import('@/views/daily-deals/edit.vue'),
      meta: {
        pageTitle: 'Edit Daily Deals',
        navActiveLink: 'daily-deals',
        breadcrumb: [
          {
            text: 'Daily Deals',
            to: { name: 'daily-deals' }
          },
          {
            text: 'Edit Daily Deal',
            active: true,
          },
        ],
      },
    },
    {
      path: '/daily-deals/create',
      name: 'daily-deals-create',
      component: () => import('@/views/daily-deals/create.vue'),
      meta: {
        pageTitle: 'Create Daily Deal',
        navActiveLink: 'daily-deals',
        breadcrumb: [
          {
            text: 'Daily Deals',
            to: { name: 'daily-deals' }
          },
          {
            text: 'Create Daily Deal',
            active: true,
          },
        ],
      },
    },
    // packages routes
    {
      path: '/packages',
      name: 'packages',
      component: () => import('@/views/packages/index.vue'),
      meta: {
        pageTitle: 'Packages',
        breadcrumb: [
          {
            text: 'Packages',
            active: true,
          },
        ],
      },
    },
    {
      path: '/packages/:id',
      name: 'packages-edit',
      component: () => import('@/views/packages/edit.vue'),
      meta: {
        pageTitle: 'Edit Package',
        navActiveLink: 'packages',
        breadcrumb: [
          {
            text: 'Packages',
            to: { name: 'packages' }
          },
          {
            text: 'Edit Package',
            active: true,
          },
        ],
      },
    },
    {
      path: '/package/create',
      name: 'package-create',
      component: () => import('@/views/packages/create.vue'),
      meta: {
        pageTitle: 'Create Package',
        navActiveLink: 'packages',
        breadcrumb: [
          {
            text: 'Packages',
            to: { name: 'packages' }
          },
          {
            text: 'Create Package',
            active: true,
          },
        ],
      },
    },

    // Marketing Offers routes
    {
      path: '/marketing-offers',
      name: 'marketing-offers',
      component: () => import('@/views/marketing-offers/index.vue'),
      meta: {
        pageTitle: 'Marketing Offers',
        breadcrumb: [
          {
            text: 'Marketing Offers',
            active: true,
          },
        ],
        permission_name: 'index marketing offers',
      },
    },
    {
      path: '/marketing-offers/products-may-you-like',
      name: 'products-may-you-like',
      component: () => import('@/views/marketing-offers/ProductsMayYouLike.vue'),
      meta: {
        pageTitle: 'Products May You Like',
        navActiveLink: 'marketing-offers',
        breadcrumb: [
          {
            text: 'Marketing Offers',
            to: { name: 'marketing-offers' }
          },
          {
            text: 'Products May You Like',
            active: true,
          },
        ],
        permission_name: 'index marketing offers'
      },
    },
    {
      path: '/marketing-offers/quick-deal',
      name: 'quick-deal',
      component: () => import('@/views/marketing-offers/QuickDeal.vue'),
      meta: {
        pageTitle: 'Quick Deal',
        navActiveLink: 'marketing-offers',
        breadcrumb: [
          {
            text: 'Marketing Offers',
            to: { name: 'marketing-offers' },
          },
          {
            text: 'Quick Deal',
            active: true,
          },
        ],
        permission_name: 'index marketing offers',
      },
    },
    {
      path: '/marketing-offers/abandoned-cart',
      name: 'abandoned-cart',
      component: () => import('@/views/marketing-offers/AbandonedCart.vue'),
      meta: {
        pageTitle: 'Abandoned Cart',
        navActiveLink: 'marketing-offers',
        breadcrumb: [
          {
            text: 'Marketing Offers',
            to: { name: 'marketing-offers' }
          },
          {
            text: 'Abandoned Cart',
            active: true,
          },
        ],
        permission_name: 'index marketing offers',
      },
    },
    {
      path: '/marketing-offers/announcement-text',
      name: 'announcement-text',
      component: () => import('@/views/marketing-offers/AnnouncementText.vue'),
      meta: {
        pageTitle: 'Announcement Text',
        navActiveLink: 'marketing-offers',
        breadcrumb: [
          {
            text: 'Marketing Offers',
            to: { name: 'marketing-offers' },
          },
          {
            text: 'Announcement Text',
            active: true,
          },
        ],
        permission_name: 'index marketing offers',
      },
    },
    {
      path: '/marketing-offers/discount-by-category',
      name: 'discount-by-category',
      component: () => import('@/views/marketing-offers/DiscountByCategory.vue'),
      meta: {
        pageTitle: 'Discount By Category',
        navActiveLink: 'marketing-offers',
        breadcrumb: [
          {
            text: 'Marketing Offers',
            to: { name: 'marketing-offers' },
          },
          {
            text: 'Discount By Category',
            active: true,
          },
        ],
        permission_name: 'index marketing offers',
      },
    },
    {
      path: '/marketing-offers/sms',
      name: 'marketing-sms',
      component: () => import('@/views/marketing-offers/MarketingSms.vue'),
      meta: {
        pageTitle: 'Marketing SMS',
        navActiveLink: 'marketing-offers',
        breadcrumb: [
          {
            text: 'Marketing Offers',
            to: { name: 'marketing-offers' },
          },
          {
            text: 'Marketing SMS',
            active: true,
          },
        ],
        permission_name: 'index marketing offers',
      },
    },
    {
      path: '/marketing-offers/random-names',
      name: 'random-names',
      component: () => import('@/views/marketing-offers/RandomNames.vue'),
      meta: {
        pageTitle: 'Random Names',
        navActiveLink: 'marketing-offers',
        breadcrumb: [
          {
            text: 'Marketing Offers',
            to: { name: 'marketing-offers' },
          },
          {
            text: 'Random Names',
            active: true,
          },
        ],
        permission_name: 'index marketing offers',
      },
    },
    {
      path: '/marketing-offers/marketing-notifications-with-criteria',
      name: 'marketing-notifications-with-criteria',
      component: () => import('@/views/marketing-offers/MarketingNotificationsWithCriteria.vue'),
      meta: {
        pageTitle: 'Marketing Notifications',
        navActiveLink: 'marketing-offers',
        breadcrumb: [
          {
            text: 'Marketing Offers',
            to: { name: 'marketing-offers' },
          },
          {
            text: 'Marketing Notifications',
            active: true,
          },
        ],
        permission_name: 'index marketing offers',
      },
    },
    {
      path: '/marketing-offers/buy-two-get-one',
      name: 'buy-two-get-one',
      component: () => import('@/views/marketing-offers/BuyTwoGetOne.vue'),
      meta: {
        pageTitle: 'Buy Two Get One',
        navActiveLink: 'marketing-offers',
        breadcrumb: [
          {
            text: 'Marketing Offers',
            to: { name: 'marketing-offers' }
          },
          {
            text: 'Buy Two Get One',
            active: true,
          },
        ],
        permission_name: 'index marketing offers',
      },
    },

    // Frontend Design routes
    {
      path: '/frontend-design',
      name: 'frontend-design',
      component: () => import('@/views/frontend-design/index.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application',
        permission_name: 'show store design',
      },
    },
    {
      path: '/frontend-design/:filter',
      name: 'frontend-design-filter',
      component: () => import('@/views/frontend-design/index.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application',
        navActiveLink: 'frontend-design',
        permission_name: 'show store design',
      },
    },

    // Store Configurations routes
    {
      path: '/store-configurations',
      name: 'store-configurations',
      component: () => import('@/views/store-configurations/index.vue'),
      meta: {
        pageTitle: 'Store Configurations',
        breadcrumb: [
          {
            text: 'Store Configurations',
            active: true,
          },
        ],
        permission_name: 'show store info'
      },
    },
    {
      path: '/store-information',
      name: 'store-information',
      component: () => import('@/views/store-configurations/StoreInfo.vue'),
      meta: {
        pageTitle: 'Store Information',
        navActiveLink: 'store-configurations',
        breadcrumb: [
          {
            text: 'Store Configurations',
            to: { name: 'store-configurations' }
          },
          {
            text: 'Store Information',
            active: true,
          },
        ],
        permission_name: 'show store info'
      },
    },
    {
      path: '/payment-services',
      name: 'payment-services',
      component: () => import('@/views/store-configurations/PaymentServices.vue'),
      meta: {
        pageTitle: 'Payment Services',
        navActiveLink: 'store-configurations',
        breadcrumb: [
          {
            text: 'Store Configurations',
            to: { name: 'store-configurations' }
          },
          {
            text: 'Payment Services',
            active: true,
          },
        ],
        permission_name: 'show payment methods'
      },
    },
    {
      path: '/payment-services/moyasar-form',
      name: 'payment-services-moyasar-form',
      component: () => import('@/views/store-configurations/payment-services/MoyasarForm.vue'),
      meta: {
        pageTitle: 'Payment Services',
        navActiveLink: 'store-configurations',
        breadcrumb: [
          {
            text: 'Store Configurations',
            to: { name: 'store-configurations' }
          },
          {
            text: 'Payment Services',
            to: { name: 'payment-services' }
          },
          {
            text: 'Moyasar',
            active: true,
          },
        ],
        permission_name: 'show payment methods'
      },
    },
    {
      path: '/shipping-services',
      name: 'shipping-services',
      component: () => import('@/views/store-configurations/ShippingServices.vue'),
      meta: {
        pageTitle: 'Shipping Services',
        navActiveLink: 'store-configurations',
        breadcrumb: [
          {
            text: 'Store Configurations',
            to: { name: 'store-configurations' }
          },
          {
            text: 'Shipping Services',
            active: true,
          },
        ],
        permission_name: 'show shipping methods'
      },
    },
    {
      path: '/other-services',
      name: 'other-services',
      component: () => import('@/views/store-configurations/OtherServices.vue'),
      meta: {
        pageTitle: 'Other Services',
        navActiveLink: 'store-configurations',
        breadcrumb: [
          {
            text: 'Store Configurations',
            to: { name: 'store-configurations' }
          },
          {
            text: 'Other Services',
            active: true,
          },
        ],
        permission_name: 'show other services'
      },
    },
    {
      path: '/other-services/qoyod-settings',
      name: 'qoyod-settings',
      component: () => import('@/views/store-configurations/other-services/QoyodSettings.vue'),
      meta: {
        pageTitle: 'Integration Settings',
        navActiveLink: 'store-configurations',
        breadcrumb: [
          {
            text: 'Store Configurations',
            to: { name: 'store-configurations' }
          },
          {
            text: 'Other Services',
            to: { name: 'other-services' }
          },
          {
            text: 'Qoyod',
            active: true,
          },
        ],
        permission_name: 'show other services'
      },
    },
    // warehouses routes
    {
      path: '/warehouses',
      name: 'warehouses',
      component: () => import('@/views/warehouses/index.vue'),
      meta: {
        pageTitle: 'Warehouses',
        navActiveLink: 'store-configurations',
        breadcrumb: [
          {
            text: 'Store Configurations',
            to: { name: 'store-configurations' }
          },
          {
            text: 'Warehouses',
            active: true,
          },
        ],
        permission_name: 'show other services'
      },
    },
    {
      path: '/warehouses/create',
      name: 'warehouses-create',
      component: () => import('@/views/warehouses/create.vue'),
      meta: {
        pageTitle: 'Create Warehouse',
        navActiveLink: 'store-configurations',
        breadcrumb: [
          {
            text: 'Store Configurations',
            to: { name: 'store-configurations' }
          },
          {
            text: 'Warehouses',
            to: { name: 'warehouses' }
          },
          {
            text: 'Create Warehouse',
            active: true,
          },
        ],
        permission_name: 'show other services'
      },
    },
    {
      path: '/warehouses/:id',
      name: 'warehouses-edit',
      component: () => import('@/views/warehouses/edit.vue'),
      meta: {
        pageTitle: 'Edit Warehouse',
        navActiveLink: 'store-configurations',
        breadcrumb: [
          {
            text: 'Store Configurations',
            to: { name: 'store-configurations' }
          },
          {
            text: 'Warehouses',
            to: { name: 'warehouses' }
          },
          {
            text: 'Edit Warehouse',
            active: true,
          },
        ],
        permission_name: 'show other services'
      },
    },
    // shipping zones routes
    {
      path: '/shipping-zones/show-orders',
      name: 'shipping-zones-show-orders',
      component: () => import('@/views/shipping-zones/show-orders.vue'),
      meta: {
        navActiveLink: 'shipping-zones-show-orders',
        permission_name: 'show orders shipping zones'
      },
    },
    {
      path: '/shipping-zones',
      name: 'shipping-zones',
      component: () => import('@/views/shipping-zones/index.vue'),
      meta: {
        pageTitle: 'Shipping Zones',
        navActiveLink: 'store-configurations',
        breadcrumb: [
          {
            text: 'Store Configurations',
            to: { name: 'store-configurations' }
          },
          {
            text: 'Shipping Zones',
            active: true,
          },
        ],
        permission_name: 'show other services'
      },
    },
    {
      path: '/shipping-zones/create',
      name: 'shipping-zones-create',
      component: () => import('@/views/shipping-zones/create.vue'),
      meta: {
        pageTitle: 'Create shipping zone',
        navActiveLink: 'store-configurations',
        breadcrumb: [
          {
            text: 'Store Configurations',
            to: { name: 'store-configurations' }
          },
          {
            text: 'Shipping Zones',
            to: { name: 'shipping-zones' }
          },
          {
            text: 'Create shipping zone',
            active: true,
          },
        ],
        permission_name: 'show other services'
      },
    },
    {
      path: '/shipping-zones/:id',
      name: 'shipping-zones-edit',
      component: () => import('@/views/shipping-zones/edit.vue'),
      meta: {
        pageTitle: 'Edit shipping zone',
        navActiveLink: 'store-configurations',
        breadcrumb: [
          {
            text: 'Store Configurations',
            to: { name: 'store-configurations' }
          },
          {
            text: 'Shipping Zones',
            to: { name: 'shipping-zones' }
          },
          {
            text: 'Edit shipping zone',
            active: true,
          },
        ],
        permission_name: 'show other services'
      },
    },
    {
      path: '/mhd-store/mhd-products/:id',
      name: 'mhd-store-mhd-product',
      component: () => import('@/views/mhd-store/MhdProducts.vue'),
      meta: {
        pageTitle: 'Mhd Store',
        breadcrumb: [
          {
            text: 'Mhd Store',
            to: { name: 'mhd-store' }
          },
          {
            text: 'Mhd Products',
            active: true,
          },
        ],
        permission_name: 'index mhd store'
      },
    },
    {
      path: '/mhd-store/checkout/:product_id/:product_option_id',
      name: 'mhd-store-checkout',
      component: () => import('@/views/mhd-store/MhdStoreCheckout.vue'),
      meta: {
        pageTitle: 'Mhd Store',
        breadcrumb: [
          {
            text: 'Mhd Store',
            to: { name: 'mhd-store' }
          },
          {
            text: 'Checkout',
            active: true,
          },
        ],
        permission_name: 'index mhd store'
      },
    },
    {
      path: '/mhd-store/pay/:mhd_order_id',
      name: 'mhd-store-pay',
      component: () => import('@/views/mhd-store/MhdStorePay.vue'),
      meta: {
        pageTitle: 'Mhd Store',
        breadcrumb: [
          {
            text: 'Mhd Store',
            to: { name: 'mhd-store' }
          },
          {
            text: 'Checkout & Pay',
            active: true,
          },
        ],
        permission_name: 'index mhd store'
      },
    },
    {
      path: '/mhd-store/payment-redirect',
      name: 'mhd-store-payment-redirect',
      component: () => import('@/views/mhd-store/MhdStorePaymentRedirect.vue'),
      meta: {
        pageTitle: 'Mhd Store',
        breadcrumb: [
          {
            text: 'Mhd Store',
            to: { name: 'mhd-store' }
          },
          {
            text: 'Checkout & Pay',
            active: true,
          },
        ],
        permission_name: 'index mhd store'
      },
    },
    // affiliate marketing routes
    {
      path: '/affiliate-marketing',
      name: 'affiliate-marketing',
      component: () => import('@/views/marketing-offers/affiliate-marketing/Index.vue'),
      meta: {
        pageTitle: 'Affiliate Marketing',
        breadcrumb: [
          {
            text: 'Affiliate Marketing',
            active: true,
          },
        ],
        // permission_name: 'index affiliate Marketing'
      },
    },
    {
      path: '/affiliate-marketing/create',
      name: 'affiliate-marketing/create',
      component: () => import('@/views/marketing-offers/affiliate-marketing/Create.vue'),
      meta: {
        pageTitle: 'Affiliate Marketing',
        breadcrumb: [
          {
            text: 'Affiliate Marketing',
            to: { name: 'affiliate-marketing' }
          },
          {
            text: 'Add New Marketer',
            active: true,
          },
        ],
        // permission_name: 'create affiliate Marketing'
      },
    },
    {
      path: '/affiliate-marketing/:id/edit',
      name: 'affiliate-marketing/edit',
      component: () => import('@/views/marketing-offers/affiliate-marketing/Edit.vue'),
      meta: {
        pageTitle: 'Affiliate Marketing',
        breadcrumb: [
          {
            text: 'Affiliate Marketing',
            to: { name: 'affiliate-marketing' }
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
        // permission_name: 'edit affiliate Marketing'
      },
    },
    {
      path: '/affiliate-marketing/settings',
      name: 'affiliate-marketing-settings',
      component: () => import('@/views/marketing-offers/affiliate-marketing/Settings.vue'),
      meta: {
        pageTitle: 'Affiliate Marketing',
        breadcrumb: [
          {
            text: 'Affiliate Marketing',
            to: { name: 'affiliate-marketing' }
          },
          {
            text: 'Settings',
            active: true,
          },
        ],
        // permission_name: 'create affiliate Marketing'
      },
    },

    // Mhd Orders
    {
      path: '/mhd-orders',
      name: 'mhd-orders',
      component: () => import('@/views/mhd-orders/index.vue'),
      meta: {
        pageTitle: 'Mhd Store Orders',
        breadcrumb: [
          {
            text: 'Mhd Store Orders',
            active: true,
          },
        ],
        permission_name: 'index mhd store'
      },
    },
    {
      path: '/mhd-orders/:id',
      name: 'mhd-orders-show',
      component: () => import('@/views/mhd-orders/Show.vue'),
      meta: {
        pageTitle: 'Mhd Store Orders',
        breadcrumb: [
          {
            text: 'Mhd Store Orders',
            to: { name: 'mhd-orders' }
          },
          {
            text: 'Show',
            active: true,
          },
        ],
        permission_name: 'index mhd store'
      },
    },
    {
      path: '/subscriptions',
      name: 'subscriptions',
      component: () => import('@/views/subscriptions/index.vue'),
      meta: {
        pageTitle: 'Subscriptions',
        breadcrumb: [
          {
            text: 'Subscriptions',
            active: true,
          },
        ],
        permission_name: 'index subscraptions'
      },
    },
    {
      path: '/subscriptions/:id',
      name: 'subscriptions-show',
      component: () => import('@/views/subscriptions/show.vue'),
      meta: {
        pageTitle: 'Subscription Show',
        breadcrumb: [
          {
            text: 'Subscriptions',
            to: { name: 'subscriptions' }
          },
          {
            text: 'Subscription Show',
            active: true,
          },
        ],
        permission_name: 'index subscraptions'
      },
    },
    {
      path: '/users-permissions',
      name: 'users-permissions',
      component: () => import('@/views/users/index.vue'),
      meta: {
        pageTitle: 'Users and Permissions',
        breadcrumb: [
          {
            text: 'Store Configurations',
            to: { name: 'store-configurations' }
          },
          {
            text: 'Users and Permissions',
            active: true,
          },
        ],
        permission_name: 'show users'
      },
    },
    {
      path: '/role',
      name: 'roles',
      component: () => import('@/views/roles/index.vue'),
      meta: {
        pageTitle: 'Roles',
        navActiveLink: 'roles',
        breadcrumb: [
          {
            text: 'Store Configurations',
            to: { name: 'store-configurations' }
          },
          {
            text: 'Users and Permissions',
            to: { name: 'users-permissions' }
          },
          {
            text: 'Roles',
            active: true,
          },
        ],
        permission_name: 'show users'
      },
    },
    {
      path: '/role/create',
      name: 'roles-create',
      component: () => import('@/views/roles/create.vue'),
      meta: {
        pageTitle: 'Create Role',
        navActiveLink: 'roles',
        breadcrumb: [
          {
            text: 'Store Configurations',
            to: { name: 'store-configurations' }
          },
          {
            text: 'Users and Permissions',
            to: { name: 'users-permissions' }
          },
          {
            text: 'Roles',
            to: { name: 'roles' }
          },
          {
            text: 'Create Role',
            active: true,
          },
        ],
        permission_name: 'show users'
      },
    },
    {
      path: '/role/edit/:id',
      name: 'roles-edit',
      component: () => import('@/views/roles/edit.vue'),
      meta: {
        pageTitle: 'Edit Role',
        navActiveLink: 'roles',
        breadcrumb: [
          {
            text: 'Store Configurations',
            to: { name: 'store-configurations' }
          },
          {
            text: 'Users and Permissions',
            to: { name: 'users-permissions' }
          },
          {
            text: 'Roles',
            to: { name: 'roles' }
          },
          {
            text: 'Edit Role',
            active: true,
          },
        ],
        permission_name: 'show users'
      },
    },
    {
      path: '/mhd-store',
      name: 'mhd-store',
      component: () => import('@/views/mhd-store/index.vue'),
      meta: {
        pageTitle: 'Mhd Store',
        breadcrumb: [
          {
            text: 'Mhd Store',
            active: true,
          },
        ],
        permission_name: 'index mhd store'
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        pageTitle: 'Login',
        layout: 'full',
        guest: true
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Register.vue'),
      meta: {
        pageTitle: 'Create Your Store',
        layout: 'full',
        guest: true
      },
    },
    {
      path: '/reset-password/:token',
      name: 'reset-password/',
      component: () => import('@/views/ResetPassword.vue'),
      meta: {
        pageTitle: 'Reset Password',
        layout: 'full',
        guest: true
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/no-permission',
      name: 'no-permission',
      component: () => import('@/views/error/NoPermission.vue'),
      meta: {
        // layout: 'full',
      },
    },
  ],
})

router.beforeEach((to,from,next) => {
    if (store.state.user && store.state.user.permissions && store.state.user.permissions.length > 0) {
      console.log('has permissions');
      if (to.name != 'no-permission' && to.name != 'home' && to.name != 'dashboard' && !store.state.user.permissions.includes(to.meta.permission_name)) {
        next("/no-permission");
      }
    }
    if(to.matched.some((record)=> record.name == 'home')) {
      if (store.state.isLoggedIn) {
        next("/dashboard");
        return ;
      }
      next('/login');
    }else if(to.matched.some((record)=>record.meta.guest)) {
        if (store.state.isLoggedIn) {
          next("/dashboard");
          return ;
        }
        next();
    } else {
      if (store.state.isLoggedIn) {
        next();
        return ;
      }
      next("/login");
    }
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
